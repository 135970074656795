<ng-container *ngIf="product$ | async as product">
  <div class="configure-product-summary">
    <h4><strong class="title">Overview</strong></h4>
    <label
      ><span class="sub-title">Part number : </span
      ><span class="productCode">{{ product.code }}</span></label
    >
    <div
      class="config-product-image"
      *ngIf="!!product.images?.GALLERY; else missingImage"
    >
      <cx-media
        class="cx-product-image waygate-product-image"
        [container]="product.images?.PRIMARY"
        format="product"
        [alt]="getProductImageAlt(product)"
      ></cx-media>
    </div>
    <ng-container *ngIf="configuration$ | async as configuration">
      <div class="">
        <div class="">
          <!-- <div class="base-price-container" *ngIf="product?.breadCrumbs[1].name!='Panametrics' || 
                    product?.breadCrumbs[1].name!='Bently Nevada'">
            <div class="base-price-label">
              {{ 'configurator.priceSummary.basePrice' | cxTranslate }}:
            </div>
            <div class="base-price-amount">
              {{ configuration?.priceSummary?.basePrice?.formattedValue }}
            </div>
          </div>

          <br> -->
          <!-- <ul class="extra-fast-premium-container">
                      <li style="margin-left: 10%;"><input type="checkbox" [(ngModel)]="isChecked" class="premium-checkbox"></li>
                      <li class="extra-fast-premium-label" >Extra Fast P</li>
                      <li class="extra-fast-premium-amount" style="margin-left:65%">$100.00</li>
                    </ul> -->
          <!-- <div class="selected-price-container" *ngIf="product?.breadCrumbs[1].name!='Panametrics' || 
                    product?.breadCrumbs[1].name!='Bently Nevada'">
            <div class="selected-price-label">
              {{ 'configurator.priceSummary.selectedOptions' | cxTranslate }}:
            </div>
            <div class="selected-price-amount">
              {{ configuration?.priceSummary?.selectedOptions?.formattedValue }}
            </div>
          </div> -->
          <hr />
          <!--           <div class="premium-container">
            <div>
              <input type="checkbox" class="premium-checkbox" style="height:17px;width:17px;background-color: red;">
              <div id="premium-label">Extra Fast Premium</div>
            </div>

            <div>$0</div>
          </div>

          <br> -->
          <div class="total-price-container">
            <div class="total-price-label">Configured Price:</div>
            <!--             <ng-template #Bently>
              <div class="total-price-label">Configured Price:</div>
            </ng-template> -->
            <div class="total-price-amount" *ngIf="totalPriceCheck">
              {{ configuration?.priceSummary?.basePrice?.formattedValue }}
            </div>
          </div>
          <br />
          <div class="total-price-container">
            <div class="total-price-label">Customer discount:</div>
            <!--             <ng-template #Bently>
              <div class="total-price-label">Configured Price:</div>
            </ng-template> -->
            <div class="total-price-amount" *ngIf="totalPriceCheck">
              {{
                configuration?.priceSummary?.currentTotalSavings?.formattedValue
              }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <hr />
    <ng-container *ngIf="container$ | async as container">
      <ng-container *ngIf="!container.routerData.displayOnly">
        <div class="cx-add-to-cart-btn-container container">
          <button class="update-price-btn" (click)="updatePrice()">
            <div>Update price</div>
            <span><mat-icon class="update-icon">credit_card</mat-icon></span>
          </button>
          <button
            *ngIf="configuration$ | async as configuration"
            [disabled]="
              !configuration?.complete ||
              configuration?.priceSummary?.basePrice?.value == 0
            "
            [ngClass]="{
              'add-to-cart-btn-config':
                configuration?.complete &&
                configuration?.priceSummary?.basePrice?.value > 0,
              'add-to-cart-btn-config-disabled':
                !configuration?.complete ||
                configuration?.priceSummary?.basePrice?.value == 0,
            }"
            (click)="addToCart(container.configuration, container.routerData)"
          >
            Add To Cart >
          </button>
        </div>
      </ng-container>
    </ng-container>

    <div *ngIf="configuration$ | async as configuration">
      <br />
      <h6 *ngIf="!configuration?.complete" class="fill-details-notification">
        Fill in all the mandatory fields * to add to cart.
      </h6>
      <h6
        *ngIf="
          configuration?.complete &&
          configuration?.priceSummary?.basePrice?.value == 0
        "
        class="fill-details-notification"
      >
        Price should not be 0 to add to cart
      </h6>
    </div>
  </div>
  <ng-template #missingImage>
    <div class="waygate-details-image">
      <cx-media
        class="cx-product-image waygate-product-image default-image"
        [container]="product?.images?.PRIMARY"
        format="product"
        [alt]="getProductImageAlt(product)"
      ></cx-media>
    </div>
  </ng-template>
</ng-container>
