import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { OCC_USER_ID_CURRENT } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { RestoreCartModel } from '../saved-cart.model';
import { SavedCartService } from '../service/saved-cart.service';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { CustomerAccountService } from '../../../core/customer-account/customer-account.service';

@Component({
  selector: 'app-view-sales-area',
  templateUrl: './view-sales-area.component.html',
  styleUrls: ['./view-sales-area.component.scss'],
})
export class ViewSalesAreaComponent implements OnInit {
  @Output() resetData: EventEmitter<any> = new EventEmitter();
  @ViewChild('mymodal') mymodal;
  cartId: any;
  salesAreaName: string;
  salesAreaId: string;
  cartName: string;
  cartModel: RestoreCartModel;
  currentSalesArea: string;
  currentSalesAreaId: string;
  currentAccount;
  currentUid;
  type;
  msgFlag: boolean = false;
  activeCart: number = 0;
  exist: boolean = false;
  activeCartId: any;
  commerceType: String;
  productLine: String;
  constructor(
    private launchDialogService: LaunchDialogService,
    private savedCartService: SavedCartService,
    private router: Router,
    private customerAccountService: CustomerAccountService,
    private activeService: ActiveCartFacade,
    private cd: ChangeDetectorRef,
    private multiCartFacade: MultiCartFacade
  ) {
    this.cartModel = new RestoreCartModel();
  }

  ngOnInit(): void {
    this.activeService.getEntries().subscribe((res) => {
      this.activeCart = res?.length;
    });
    this.customerAccountService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });
    this.savedCartService.getValidation().subscribe((res) => {
      if (res?.data) {
        this.cartId = res?.data.code;
        this.cartName = res?.data.name;
        this.salesAreaName = res.data?.saleaAreaName;
        this.salesAreaId = res.data?.saleaAreaID;
        this.type = res?.type;
        this.commerceType = res?.commerceType;
      }
    });

    this.activeService.getActiveCartId().subscribe((cartId) => {
      this.activeCartId = cartId;
    });

    this.getCurrentSalesArea();
  }

  getCurrentSalesArea() {
    this.customerAccountService.getCurrentCustomerAccount().subscribe((res) => {
      if (res) {
        this.currentAccount = res?.name;
        this.currentUid = res?.uid;
        this.currentSalesArea = res?.selectedSalesArea?.salesAreaName;
        this.currentSalesAreaId = res?.selectedSalesArea?.salesAreaId;
        if (
          this.currentSalesArea !== this.salesAreaName &&
          this.currentSalesAreaId !== this.salesAreaId
        ) {
          this.msgFlag = true;
        }
      } else {
        this.msgFlag = false;
        this.currentSalesArea = '';
        this.currentSalesAreaId = '';
      }
    });
  }

  restoreCart() {
    this.changeSalesArea();
    this.cartModel.cartName = this.cartName;
    this.cartModel.keepRestoredCart = false;
    this.cartModel.preventSaveActiveCart = false;
    this.savedCartService
      .restorCart(this.cartId, this.cartModel)
      .subscribe((res) => {
        this.closeModal();
        this.multiCartFacade.loadCart({
          cartId: this.cartId,
          userId: OCC_USER_ID_CURRENT,
          extraData: {
            active: true,
          },
        });
        this.launchDialogService.closeDialog('restored');
        if (this.type == 'detail') {
          if (this.commerceType == 'RETURNS') {
            this.router.navigate(['rma/cart']);
          } else {
            window.location.href = `${this.productLine}/cart`;
          }
        } else {
          this.savedCartService.setValidation({ data: null, type: 'normal' });
        }
      });
  }

  changeSalesArea() {
    if (
      this.currentSalesArea !== this.salesAreaName &&
      this.currentSalesAreaId !== this.salesAreaId
    ) {
      this.customerAccountService
        .updateSalesArea(this.salesAreaId, this.currentUid)
        .subscribe((res: any) => {
          this.cd.detectChanges();
          this.customerAccountService.updateAvaiableProductLine(
            res?.visibleCategories || []
          );
          this.customerAccountService.refreshPostCustomAccSwitch(
            this.salesAreaId.split('_')[1]
          );
        });
    }
  }

  checkCartBeforeSave() {
    this.exist = false;
    let param = {
      page: 0,
      saveCartName: this.cartName,
      show: 'Page',
    };
    if (this.activeCart > 0) {
      this.savedCartService
        .checkExistingName(param, this.activeCartId)
        .subscribe((res) => {
          if (res == true) {
            this.exist = true;
          }
          if (res == false) {
            this.restoreCart();
          }
        });
    } else {
      this.restoreCart();
    }
  }

  onChange(e, type) {
    if (type === 'cart') {
      this.cartName = e.target.value;
    }
  }

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy() {
    this.savedCartService.setValidation(null);
  }
}
