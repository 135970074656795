export const REGULAR_PATTERN = {
  textOnly: /[^a-zA-Z. ]+/g,
  numbersOnly: /[^\d+ .]+/g,
  alphaNumeric: /[^a-zA-Z0-9 .+-/]+/g,
  alphaNumericOnlyForAllLang: /[^\p{L}\p{N}]+/gu,
  alphaNemericOnly: /[^a-zA-Z0-9 -]+/g,
  alphaNumericWithSpecialCharater: /[^a-zA-Z0-9,-.+/%#@&$ ]+/g,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  // phoneNumberRegex: /^(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/g,
  phoneNumberRegex: /^[a-zA-Z0-9-.+()# ]{6,20}$/,
};

export const testRegex = (str, regexPattern) => {
  const sanitizedString = str.replace(regexPattern, '');
  return sanitizedString;
};
